<template>
  <v-dialog
    :value="true"
    max-width="800"
    scrollable
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ $t('createDashboardCollection') }}
      </v-card-title>
      <v-card-text class="pt-5">
        <v-card>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('dashboardTitle')"
                v-model="dashCollection.name"
                persistent-hint
                required
                :hint="$t('chooseATitle')"
                style="padding-left:20px; padding-right:20px"
                :error="missingRequiredInput"
              />
            </v-col>
          </v-row>
          <div>
            <v-alert
              v-if="showError"
              dense
              type="error"
              transition="slide-x-transition"
            >
              {{ $t('couldNotCreateDashCollection') }}
            </v-alert>
          </div>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-row
          dense
          justify="space-between"
        >
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              @click="close"
              color="error"
              large
              block
              text
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              large
              color="primary"
              block
              @click="createDashCollection"
            >
              {{ $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
	name: "AddDashCollectionDialog",
	components: {

	},
	data() {
		return {
      dashCollection: {
        name: null,
        channel: null,
        data: null,
        period: null,
        channels: null,
        timeIntervalFrom: null,
        timeIntervalTo: null,
        endTimeInMillis: null,
        startTimeInMillis: null
      },
      missingRequiredInput: false,
      showError: false
    }
	},
  computed: {
  },
	watch: {
	},
	methods: {
		close() {
			this.$emit('changeTabs')
		},
    create() {
      this.$emit('newTab')
    },
    createDashCollection() {
      if(this.dashCollection.name) {
        this.$store.dispatch('createDashCollection', this.dashCollection).then(result => {
          if(result) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('youHaveSuccessfullyCreatedNewCollection')
            })
            this.create()
          }
          else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('couldNotCreateDashboardCollection')
            })
            this.showError = true
          }
        })
        .catch(() => {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotCreateDashboardCollection')
          })
          this.showError = true
        })
      }
      else {
          this.missingRequiredInput = true
      }
    }
  }
}
</script>
